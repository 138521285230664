export const createLocaleTextGetter = languageCode => {
  const languages = [languageCode, "fi"] // last language in array is default;
  const localize = value => {
    if (Array.isArray(value)) {
      return value.map(v => localize(v, languages))
    } else if (typeof value == "object") {
      if (value === null)
        return ''
      if (/^locale[A-Z]/.test(value._type) || isLocalizedField(value)) {
        const language = languages.find(lang => value[lang])
        if (Array.isArray(value[language])) {
          value[language].map(v =>
            value[language][v] = localize(v, languages))
        }

        return Object.keys(value).reduce((result, key) => {
          result[key] = localize(value[key], languages)
          return result
        }, {})

        return value[language]
      }
      return Object.keys(value).reduce((result, key) => {
        result[key] = localize(value[key], languages)
        return result
      }, {})
    }
    return value
  }
  return localize
}


function isLocalizedField  (field) {
  if (typeof field['fi'] !== "undefined")
    return true;

  if (typeof field['en'] !== "undefined")
    return true;


  if (typeof field['enGB'] !== "undefined")
    return true;


  if (typeof field['enCA'] !== "undefined")
    return true;

  return false;
}


function getLocales(locale) {
  if (locale === 'fi')
    return ['fi']
  if (locale === 'enGB' || locale === 'uk')
    return ['enGB', 'en']
  if (locale === 'enCA' || locale === 'ca')
    return ['enCA', 'en']
  if (locale === 'en')
    return ['en']

  return [locale];
}

function localize(value, languages) {
  if (languages[0])
    languages = getLocales(languages[0])

  if (Array.isArray(value)) {
    return value.map(v => localize(v, languages))
  } else if (typeof value == 'object') {
    if (value === null)
      return ''
    if (/^locale[A-Z]/.test(value._type) || isLocalizedField(value)) {
      const language = languages.find(lang => value[lang])
      if (Array.isArray(value[language])) {
        return value[language].map(v => localize(v, languages))
      }
      return value[language]
    }

    return Object.keys(value).reduce((result, key) => {
      result[key] = localize(value[key], languages)
      return result
    }, {})
  }
  return value
}

export const getLocaleAlias = (locale) => {
  let localeAlias = 'en';
  if (locale === 'en')
    localeAlias = 'en';

  if (locale === 'enCA')
    localeAlias = 'ca';

  if (locale === 'enGB')
    localeAlias = 'uk'

  if (locale === 'fi')
    localeAlias = 'fi'

  return localeAlias;
}
export const  localizeItem = (value, languages) => {
  if (languages[0])
    languages = getLocales(languages[0])

  if (Array.isArray(value)) {
    return value.map(v => localizeItem(v, languages))
  } else if (typeof value == "object") {
    if (value === null)
      return ''
    if (/^locale[A-Z]/.test(value._type) || isLocalizedField(value)) {
      const language = languages.find(lang => value[lang])
      if (Array.isArray(value[language])) {
        value[language].forEach(v => {
          value[language][v] = localizeItem(v, languages)
        })
      }
      return value[language]
    }
    return Object.keys(value).reduce((result, key) => {
      result[key] = localizeItem(value[key], languages)
      return result
    }, {})
  }
  return value
}