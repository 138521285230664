import React from "react"
import { ThemeProvider } from "./src/context/ThemeContext"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles"
import translations from "./static/global/translations.json"
import ranking from "./static/global/ranking.json"
import bonuses from "./static/global/bonuses.json"
import cjson from 'compressed-json'

import { createMuiTheme } from "@material-ui/core"
import ReactDOM from "react-dom"
import ErrorBoundary from "./src/components/errorBoundary"

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ff9133",
      main: "#ff7600",
      dark: "#b25200",
      contrastText: "#fff",
    },
    secondary: {
      light: "#49aff7",
      main: "#1c9cf5",
      dark: "#136dab",
      contrastText: "#fff",
    },
  },
  spacing: 2,
})

export const wrapRootElement = ({ element }) => {
  const rankingD = cjson.decompress(ranking);
  const translationsD = cjson.decompress(translations);
  const bonusesD = cjson.decompress(bonuses);
  return (
    <ErrorBoundary>
      <ThemeProvider ranking={rankingD} translations={translationsD} bonuses={bonusesD}>
        <MuiThemeProvider theme={theme}>{element}</MuiThemeProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}
