import React, { useEffect, useState } from "react"
import _ from "../services/NullIf"
import { globalHistory } from "@reach/router"
import h from "../services/GenericHelpers"
import { createLocaleTextGetter, getLocaleAlias, localizeItem } from "../graphql-assistant/createLocaleTextGetter"
import { localizeCasinoData, localizeRankingData } from "../services/localize-helpers"
import { navigate } from "gatsby"
import { canUseDOM } from '../services/dom-helpers'
import { API_ROOT } from "gatsby-env-variables"
import { fetchProfile, verifyProfile } from "../api/client/users"

const defaultState = {
  dark: false,
  toggleDark: () => {},
}

const getParameterByName = (name, url) => {
  if (canUseDOM) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  return ""
}

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = useState(
    canUseDOM ? (localStorage.getItem(localStorageKey) || null) : null
  );
  useEffect(() => {
    // return canUseDOM ? localStorage.setItem(localStorageKey, JSON.stringify(value)) : null
  }, [value]);
  return [value, setValue];
}

const ThemeContext = React.createContext()
const ThemeProvider = props => {
  let ranking = props.ranking ? props.ranking: []
  let translations = props.translations

  let children = props.children

  let url = ''
  let propUrl = ""
  let locale = ""
  try {
    propUrl =
      props.children.props.children.props.children.props.url
    url = props.children.props.children.props.children.props.url
  } catch (err) {
    // console.log('error : ' + JSON.stringify(props));
  }

  if (_.isNotNullOrEmpty(propUrl)) {
    locale = _.getCurrentLocale(propUrl)
  }

  // fallback to globalhistory
  if (
    !_.isNotNullOrEmpty(locale) &&
    _.isNotNullOrEmpty(globalHistory.location) &&
    _.isNotNullOrEmpty(globalHistory.location.pathname)
  ) {
    url = globalHistory.location.pathname;
    locale = _.getCurrentLocale(globalHistory.location.pathname)
  }

  let [ loading, setLoading ] = useState(true);
  let languages = [locale];
  let bonusesLocalized = props.bonuses ? localizeItem(props.bonuses, languages)
    .map(x => localizeItem(x, languages))
    .filter( x => _.isNotNullOrEmpty(x.locale) && x.locale.some(y => y === getLocaleAlias(locale)))
    .sort((a, b) => {
      if (h.casinoAverageRating(b) > h.casinoAverageRating(a))
        return 1;
      if (h.casinoAverageRating(b) < h.casinoAverageRating(a))
        return -1;

      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    }) : [];

  let localeRanking = localizeItem(ranking[0], languages);
  if (localeRanking && localeRanking.casinos){
    localeRanking.casinos.slice().reverse().forEach(casino => {
      bonusesLocalized = [...bonusesLocalized.filter(item => item.name === casino.name),
        ...bonusesLocalized.filter(item => item.name !== casino.name)];
    })
  }

  let [ bonuses, setBonuses ]  = useState(bonusesLocalized);

  let [location, setLocation] = useState(locale)

  let [errors, setErrors] = useState({})
  let [profile, setProfile] = useState({})
  let [verificationResult, setVerificationResult] = useState({ status: 'none', msg: undefined })
  let [loggedInUser, setLoggedInUser] = useStateWithLocalStorage('user');

  let [currentUrl, setCurrentUrl] = useState(url);
  const token = getParameterByName('code'),
        email = getParameterByName('email')

  const verificationToken = getParameterByName('token')
  const user = canUseDOM ? JSON.parse(localStorage.getItem('oauth')) || null : null

  if (verificationToken && email && canUseDOM && verificationResult.status === 'none') {
    verifyProfile(verificationToken, email).then(res => {
      if (res.user && res.user.token) {
        if (Object.keys(profile).length === 0) {
          localStorage.setItem('oauth', JSON.stringify({token: res.user.token, email: res.user.email}))
          fetchProfile().then(user => {
            setProfile(user)
          }).catch(err => {
            localStorage.setItem('oauth', JSON.stringify({}));
            setProfile({})
            setErrors(err)
          })
        }
      }
      setVerificationResult(res)
    }).catch(err => {
      localStorage.setItem('oauth', JSON.stringify({}));
      setProfile({})
      console.log(err)
      setErrors(err)
    })
  }

  if (token && email && canUseDOM) {
    if (!(user && user.token === token && user.email === email))
      localStorage.setItem('oauth', JSON.stringify({token: token, email: email}))
      if (Object.keys(profile).length === 0) {
        fetchProfile()
          .then(res => {
            setProfile(res);
          })
          .catch(err => {
            localStorage.setItem('oauth', JSON.stringify({}));
            setProfile({})
            setErrors(err);
          })
      }
  }

  useEffect(() => {
    //console.log(profile, user)
    if (Object.keys(profile).length === 0 && user && user.token) {
      //console.log('fetching user')
      fetchProfile()
        .then(res => {
          console.log(JSON.stringify(res))
          setProfile(res)
        } )
        .catch(err => {
          localStorage.setItem('oauth', JSON.stringify({}));
          setProfile({})
          setErrors(err)
        });
    }
  }, []);

  let activeTranslations = localizeItem(translations, languages);

  let [loginOpen, setLoginOpen] = useState(false)
  let [loginIndex, setLoginIndex] = useState(0)

  return (
    <ThemeContext.Provider
      value={{
        path: currentUrl,
        translations: activeTranslations,
        bonuses: bonuses ? bonuses : [],
        setBonuses: setBonuses,
        locale: location,
        loading: loading,
        setLocation: setLocation,
        ranking: localeRanking,
        user: loggedInUser,
        login: setLoggedInUser,
        profile: profile,
        setProfile : setProfile,
        verificationResult: verificationResult,
        setVerificationResult : setVerificationResult,
        loginOpen: loginOpen,
        setLoginOpen: setLoginOpen,
        loginIndex: loginIndex,
        setLoginIndex: setLoginIndex,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
export default ThemeContext
export { ThemeProvider }