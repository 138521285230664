class Nullif {
  static isNotNull(item) {
    return typeof item !== "undefined" && item !== null
  }

  static isNotNullOrEmpty(item) {
    return this.isNotNull(item) && item !== ""
  }

  static take(array, n) {
    if (!this.isNotNull(array)) return void 0
    if (!this.isNotNull(n)) return array[0]
    if (n < 0) return []
    return array.slice(0, n)
  }

  static getBreadcrumbs(pathName) {
    return pathName.split("/")
  }
  // this can be used as a mini hack
  // until we set up the locale to be set in the context
  static getCurrentLocale(pathName) {
    if (pathName.startsWith('/mene') || pathName.startsWith('/go'))
    {
      if (pathName.startsWith('/mene')) {
        return 'fi'
      }
      if (pathName.startsWith('/go-ca')) {
        return 'ca'
      }
      if (pathName.startsWith('/go-uk')) {
        return 'uk'
      }
      if (pathName.startsWith('/go')) {
        return 'en'
      }
    }

    if (pathName.startsWith("/en"))
      return "en";

    if (pathName.startsWith("/ca"))
      return "enCA";

    if (pathName.startsWith("/uk"))
      return "enGB";

    return "fi";
  }

  static emptyIfNull(item) {
    if (this.isNotNull(item)) return item

    return ""
  }
}

export default Nullif
