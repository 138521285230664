import _ from "../services/NullIf"

class GetTranslationValue {
  static get(translations, name) {
    if (_.isNotNull(translations)) {
      const res = translations.find(
        x =>
          _.isNotNull(x) &&
          _.isNotNull(x.baseName) &&
          _.isNotNull(name) &&
          x.baseName.toLowerCase() === name.toLowerCase()
      )
      if (_.isNotNull(res) && _.isNotNullOrEmpty(res.value)) return res.value
    }

    return "!!" + name + "!!"
  }

  static getBool(translations, value) {
    if (value) return GetTranslationValue.get(translations, "yes")

    return GetTranslationValue.get(translations, "no")
  }
}

export default GetTranslationValue
