export const localizeCasinoData = (casinos, locale) => {
  let localizedCasinos = [];

  casinos.forEach(x => {
    let activeCasino = x;
    if (x.bonuses){
      activeCasino.bonuses = x.bonuses[locale];
    }
    localizedCasinos.push(activeCasino)
  })

  return localizedCasinos;
}

export const getLocaleFromUrl = (url) => {
  const locales = getAllLocaleAlias()
  for (const l of locales) {
    if (url) {
      if (url.includes("https://mr-gamble.com/" + l + "/"))
        return getLocaleFromAlias(l);
    }
  }
}

const getAllLocaleAlias = () => {
  return ['en', 'uk', 'fi', 'ca'];
}


const getLocaleFromAlias = (alias) => {
  if (alias === 'en')
    return 'en';
  if (alias ===  'fi')
    return 'fi';
  if (alias === 'uk')
    return 'enGB';
  if (alias === 'ca')
    return 'enCA';
}

export const localizeRankingData = (ranking, locale) => {
  let localizedRanking = ranking;
  if (ranking.casinos) {
    ranking.casinos = ranking.casinos[locale];
  }
  else {
    ranking.casinos = []
  }

  return ranking;
}
