// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-big-wins-index-js": () => import("./../src/templates/big-wins/index.js" /* webpackChunkName: "component---src-templates-big-wins-index-js" */),
  "component---src-templates-casino-bonus-freespins-js": () => import("./../src/templates/casino-bonus/freespins.js" /* webpackChunkName: "component---src-templates-casino-bonus-freespins-js" */),
  "component---src-templates-casino-bonus-item-js": () => import("./../src/templates/casino-bonus/item.js" /* webpackChunkName: "component---src-templates-casino-bonus-item-js" */),
  "component---src-templates-casino-category-baccarat-js": () => import("./../src/templates/casino-category/baccarat.js" /* webpackChunkName: "component---src-templates-casino-category-baccarat-js" */),
  "component---src-templates-casino-category-banking-providers-unified-banking-index-js": () => import("./../src/templates/casino-category/banking-providers/unified-banking-index.js" /* webpackChunkName: "component---src-templates-casino-category-banking-providers-unified-banking-index-js" */),
  "component---src-templates-casino-category-banking-providers-unified-banking-item-js": () => import("./../src/templates/casino-category/banking-providers/unified-banking-item.js" /* webpackChunkName: "component---src-templates-casino-category-banking-providers-unified-banking-item-js" */),
  "component---src-templates-casino-category-bingo-js": () => import("./../src/templates/casino-category/bingo.js" /* webpackChunkName: "component---src-templates-casino-category-bingo-js" */),
  "component---src-templates-casino-category-blackjack-js": () => import("./../src/templates/casino-category/blackjack.js" /* webpackChunkName: "component---src-templates-casino-category-blackjack-js" */),
  "component---src-templates-casino-category-craps-js": () => import("./../src/templates/casino-category/craps.js" /* webpackChunkName: "component---src-templates-casino-category-craps-js" */),
  "component---src-templates-casino-category-e-wallet-js": () => import("./../src/templates/casino-category/e-wallet.js" /* webpackChunkName: "component---src-templates-casino-category-e-wallet-js" */),
  "component---src-templates-casino-category-exclusive-bonuses-js": () => import("./../src/templates/casino-category/exclusive-bonuses.js" /* webpackChunkName: "component---src-templates-casino-category-exclusive-bonuses-js" */),
  "component---src-templates-casino-category-gamification-js": () => import("./../src/templates/casino-category/gamification.js" /* webpackChunkName: "component---src-templates-casino-category-gamification-js" */),
  "component---src-templates-casino-category-keno-js": () => import("./../src/templates/casino-category/keno.js" /* webpackChunkName: "component---src-templates-casino-category-keno-js" */),
  "component---src-templates-casino-category-licences-index-js": () => import("./../src/templates/casino-category/licences/index.js" /* webpackChunkName: "component---src-templates-casino-category-licences-index-js" */),
  "component---src-templates-casino-category-licences-item-js": () => import("./../src/templates/casino-category/licences/item.js" /* webpackChunkName: "component---src-templates-casino-category-licences-item-js" */),
  "component---src-templates-casino-category-live-casino-js": () => import("./../src/templates/casino-category/live-casino.js" /* webpackChunkName: "component---src-templates-casino-category-live-casino-js" */),
  "component---src-templates-casino-category-lotto-js": () => import("./../src/templates/casino-category/lotto.js" /* webpackChunkName: "component---src-templates-casino-category-lotto-js" */),
  "component---src-templates-casino-category-mobile-friendly-js": () => import("./../src/templates/casino-category/mobile-friendly.js" /* webpackChunkName: "component---src-templates-casino-category-mobile-friendly-js" */),
  "component---src-templates-casino-category-no-account-js": () => import("./../src/templates/casino-category/no-account.js" /* webpackChunkName: "component---src-templates-casino-category-no-account-js" */),
  "component---src-templates-casino-category-no-sticky-js": () => import("./../src/templates/casino-category/no-sticky.js" /* webpackChunkName: "component---src-templates-casino-category-no-sticky-js" */),
  "component---src-templates-casino-category-roulette-js": () => import("./../src/templates/casino-category/roulette.js" /* webpackChunkName: "component---src-templates-casino-category-roulette-js" */),
  "component---src-templates-casino-category-scratchcards-js": () => import("./../src/templates/casino-category/scratchcards.js" /* webpackChunkName: "component---src-templates-casino-category-scratchcards-js" */),
  "component---src-templates-casino-category-sportsbook-js": () => import("./../src/templates/casino-category/sportsbook.js" /* webpackChunkName: "component---src-templates-casino-category-sportsbook-js" */),
  "component---src-templates-casino-category-tax-exempt-js": () => import("./../src/templates/casino-category/tax-exempt.js" /* webpackChunkName: "component---src-templates-casino-category-tax-exempt-js" */),
  "component---src-templates-casino-category-video-poker-js": () => import("./../src/templates/casino-category/video-poker.js" /* webpackChunkName: "component---src-templates-casino-category-video-poker-js" */),
  "component---src-templates-casino-category-vip-program-js": () => import("./../src/templates/casino-category/vip-program.js" /* webpackChunkName: "component---src-templates-casino-category-vip-program-js" */),
  "component---src-templates-casino-games-slot-js": () => import("./../src/templates/casino-games/slot.js" /* webpackChunkName: "component---src-templates-casino-games-slot-js" */),
  "component---src-templates-casino-group-index-js": () => import("./../src/templates/casino-group/index.js" /* webpackChunkName: "component---src-templates-casino-group-index-js" */),
  "component---src-templates-casino-group-item-js": () => import("./../src/templates/casino-group/item.js" /* webpackChunkName: "component---src-templates-casino-group-item-js" */),
  "component---src-templates-casino-language-index-js": () => import("./../src/templates/casino-language/index.js" /* webpackChunkName: "component---src-templates-casino-language-index-js" */),
  "component---src-templates-casino-language-item-js": () => import("./../src/templates/casino-language/item.js" /* webpackChunkName: "component---src-templates-casino-language-item-js" */),
  "component---src-templates-casino-news-index-js": () => import("./../src/templates/casino-news/index.js" /* webpackChunkName: "component---src-templates-casino-news-index-js" */),
  "component---src-templates-casino-news-item-js": () => import("./../src/templates/casino-news/item.js" /* webpackChunkName: "component---src-templates-casino-news-item-js" */),
  "component---src-templates-casino-platforms-index-js": () => import("./../src/templates/casino-platforms/index.js" /* webpackChunkName: "component---src-templates-casino-platforms-index-js" */),
  "component---src-templates-casino-platforms-item-js": () => import("./../src/templates/casino-platforms/item.js" /* webpackChunkName: "component---src-templates-casino-platforms-item-js" */),
  "component---src-templates-casino-review-index-js": () => import("./../src/templates/casino-review/index.js" /* webpackChunkName: "component---src-templates-casino-review-index-js" */),
  "component---src-templates-casino-review-item-js": () => import("./../src/templates/casino-review/item.js" /* webpackChunkName: "component---src-templates-casino-review-item-js" */),
  "component---src-templates-game-providers-freespins-item-js": () => import("./../src/templates/game-providers/freespins/item.js" /* webpackChunkName: "component---src-templates-game-providers-freespins-item-js" */),
  "component---src-templates-game-providers-index-js": () => import("./../src/templates/game-providers/index.js" /* webpackChunkName: "component---src-templates-game-providers-index-js" */),
  "component---src-templates-game-providers-item-js": () => import("./../src/templates/game-providers/item.js" /* webpackChunkName: "component---src-templates-game-providers-item-js" */),
  "component---src-templates-generic-item-js": () => import("./../src/templates/generic/item.js" /* webpackChunkName: "component---src-templates-generic-item-js" */),
  "component---src-templates-homepage-index-js": () => import("./../src/templates/homepage/index.js" /* webpackChunkName: "component---src-templates-homepage-index-js" */),
  "component---src-templates-my-account-index-js": () => import("./../src/templates/my-account/index.js" /* webpackChunkName: "component---src-templates-my-account-index-js" */),
  "component---src-templates-redirect-js": () => import("./../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */),
  "component---src-templates-special-landing-big-bonuses-js": () => import("./../src/templates/special-landing/big-bonuses.js" /* webpackChunkName: "component---src-templates-special-landing-big-bonuses-js" */),
  "component---src-templates-special-landing-bonus-code-js": () => import("./../src/templates/special-landing/bonus-code.js" /* webpackChunkName: "component---src-templates-special-landing-bonus-code-js" */),
  "component---src-templates-special-landing-deposit-bonuses-js": () => import("./../src/templates/special-landing/deposit-bonuses.js" /* webpackChunkName: "component---src-templates-special-landing-deposit-bonuses-js" */),
  "component---src-templates-special-landing-min-deposit-js": () => import("./../src/templates/special-landing/min-deposit.js" /* webpackChunkName: "component---src-templates-special-landing-min-deposit-js" */),
  "component---src-templates-special-landing-new-casinos-js": () => import("./../src/templates/special-landing/new-casinos.js" /* webpackChunkName: "component---src-templates-special-landing-new-casinos-js" */),
  "component---src-templates-special-landing-raffle-js": () => import("./../src/templates/special-landing/raffle.js" /* webpackChunkName: "component---src-templates-special-landing-raffle-js" */),
  "component---src-templates-verify-email-verify-email-js": () => import("./../src/templates/verify-email/verify-email.js" /* webpackChunkName: "component---src-templates-verify-email-verify-email-js" */)
}

