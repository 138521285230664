import React, { useEffect, useState } from "react"
import _ from "../services/NullIf"
import styles from "./errorBoundary.module.scss"
import t from "../services/GetTranslationValue"

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div><div className={`${styles.parent}`}>

        <div className={`${styles.container}`}>

          <p>Something went wrong.</p>
          <p>We've logged the error, but please restart your journey, and if you continue to get issues,
            please send an email to tech@cashmagnet.money, and we'll help you out.</p>

          <img className={`${styles.gambleLogo}`} src={`/icons/tv_set@3x.png`} alt="Mr Gamble" />
          <p>Select locale - Valitse Sijainti</p>
          <a href={'/ca/'}>
            <img src={`/icons/canada.svg`} />
            <span>Canadian casinos and bonuses</span>
          </a>
          <a href={'/uk/'}>
            <img src={`/icons/united-kingdom.png`} />
            <span>UK casinos and bonuses</span>
          </a>
          <a href={'/fi/'} aria-label={`Finnish`}>
            <img src={`/icons/finland.png`} />
            <span>Suomalaiset Kasinot ja Bonukset</span>
          </a>
          <a href={'/en/'}>
            <img src={`/icons/global.svg`} />
            <span>Online Casinos and bonuses</span>
          </a>
          <img className={`${styles.warningLogo}`} alt={`age restriction`} src={`/icons/Artboard-32.svg`} />
        </div>
      </div>
      </div>;
    }

    return this.props.children;
  }
}