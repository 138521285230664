
class GenericHelpers {

  static getColor() {
    let colors = ['rgba(0, 125, 163, 1)', 'rgba(253, 185, 19, 1)', 'rgba(114, 191, 68, 1)', 'rgba(218, 33, 40, 1)', 'rgba(243, 114, 1, 1)'];
    return colors[Math.floor(Math.random() * colors.length)];
  }

  static showMore(baseString, length, showMoreText){
    if (baseString.length < length)
      return baseString;

    return baseString.substring(0, length) + showMoreText;
  }

  static casinoAverageRating(casino){

           let totalRating = casino.bonusRating +
             casino.customerServiceRating +
             casino.gameVarietyRating +
             casino.transferRating +
             casino.mobileRating +
             casino.usabilityRating +
            GenericHelpers.getResponsibleGamingRating(casino);

           const averageRating = totalRating / 7;

           return (averageRating).toFixed(2);
         }



    static getResponsibleGamingRating (casino) {
      let total = 0;
      if (casino.extendedData){
        if (casino.extendedData.hasTimeOutPeriod) {
          total++;
        }
        if (casino.extendedData.hasSelfExclusion) {
          total++;
        }
        if (casino.extendedData.hasSelfAssessmentTest) {
          total++;
        }

        if (casino.extendedData.hasOnlineTransactionHistory) {
          total++;
        }

        if (casino.extendedData.hasFinancialLimits) {
          total++;
        }

        if (casino.extendedData.hasDirectLinkToHelpOrganisation) {
          total++;
        }

        if (casino.extendedData.realityCheckReminder) {
          total++;
        }
      }

      if (total < 1)
        return 1;
      if (total <= 3)
        return 2;
      if (total <= 5)
        return 3;
      if (total <= 6)
        return 4;
      if (total <= 7)
        return 5;

      return 0;
    }
  static removeAccents (str) {
    var accents =
      "ÀÁÂÃÄÅĄĀāàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďDŽdžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏĪìíîïīÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚŞšśşŤťŸÝÿýŽŻŹžżźđĢĞģğ"
    var accentsOut =
      "AAAAAAAAaaaaaaaasOOOOOOOOoooooooDdDZdzEEEEEeeeeeeCcCcCcDIIIIIiiiiiUUUUUuuuuuLLLlllNNNnnnRrSSSsssTtYYyyZZZzzzdGGgg"

    str = str.split("")
    str.forEach((letter, index) => {
      let i = accents.indexOf(letter)
      if (i != -1) {
        str[index] = accentsOut[i]
      }
    })
    return str
      .join("")
      .replace(/[^0-9a-zA-Z_\-/]/g, "")
      .toLowerCase()
  }
}

export default GenericHelpers