import { API_ROOT } from "gatsby-env-variables"

export async function fetchProfile() {
  const user = localStorage ? (JSON.parse(localStorage.getItem('oauth')) || null) : null
  let headers = { 'Authorization': `Token ${user.token}`}
  const res = await fetch(`${API_ROOT}/profile`, {
    headers: headers,
    mode: 'cors'
  });
  return res.json();
}

export async function verifyProfile(token, email) {
  let url = new URL(`${API_ROOT}/verification`)

  url.searchParams.append('token', token)
  url.searchParams.append('email', email)

  const res = await fetch(url);
  return res.json()
}